export const usersConstants = {
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  SET_FETCH_USERS_LOADING: 'SET_FETCH_USERS_LOADING',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  BLOCK_USERS_SUCCESS: 'BLOCK_USERS_SUCCESS',
  BLOCK_USERS_FAILURE: 'BLOCK_USERS_FAILURE',
  DEACTIVATE_WP_USERS_SUCCESS: 'DEACTIVATE_WP_USERS_SUCCESS',
  DEACTIVATE_WP_USERS_FAILURE: 'DEACTIVATE_WP_USERS_FAILURE',
  EXPORT_USERS_SUCCESS: 'EXPORT_USERS_SUCCESS',
  EXPORT_USERS_FAILURE: 'EXPORT_USERS_FAILURE',
  VALIDATE_USER_SUCCESS: 'VALIDATE_USER_SUCCESS',
  VALIDATE_USER_FAILURE: 'VALIDATE_USER_FAILURE',
  ADD_WP_USER_SUCCESS: 'ADD_WP_USER_SUCCESS',
  ADD_WP_USER_FAILURE: 'ADD_WP_USER_FAILURE',
  UPDATE_WP_USER_SUCCESS: 'UPDATE_WP_USER_SUCCESS',
  UPDATE_WP_USER_FAILURE: 'UPDATE_WP_USER_FAILURE',
  UPDATE_WP_USER_STATUS_SUCCESS: 'UPDATE_WP_USER_STATUS_SUCCESS',
  UPDATE_WP_USER_STATUS_FAILURE: 'UPDATE_WP_USER_STATUS_FAILURE',
  BULK_USERS_STATUS_SUCCESS: 'BULK_USERS_STATUS_SUCCESS',
  BULK_USERS_STATUS_FAILURE: 'BULK_USERS_STATUS_FAILURE'
};

const fetchUsersSuccess = (users, roles, searchText) => {
  return {
    type: usersConstants.FETCH_USERS_SUCCESS,
    payload: users.data,
    roles: roles,
    search: searchText
  };
};

const setFetchUsersLoading = () => {
  return {
    type: usersConstants.SET_FETCH_USERS_LOADING
  };
};

const fetchUsersFailure = (error) => {
  return { type: usersConstants.FETCH_USERS_FAILURE, payload: error };
};

const addUserSuccess = (user) => {
  return { type: usersConstants.ADD_USER_SUCCESS, payload: user.data };
};

const addUserFailure = (error) => {
  return { type: usersConstants.ADD_USER_FAILURE, payload: error };
};

const updateUserSuccess = (user) => {
  return { type: usersConstants.UPDATE_USER_SUCCESS, payload: user.data };
};

const updateUserFailure = (error) => {
  return { type: usersConstants.UPDATE_USER_FAILURE, payload: error };
};

const resetUserPasswordSuccess = (userData) => {
  return { type: usersConstants.RESET_USER_PASSWORD, payload: userData.data };
};

const updateUserActiveSuccess = (userData) => {
  return { type: usersConstants.UPDATE_USER_STATUS, payload: userData.data };
};

const blockUsersSuccess = (users) => {
  return { type: usersConstants.BLOCK_USERS_SUCCESS, payload: users.data };
};

const blockUsersFailure = (error) => {
  return { type: usersConstants.BLOCK_USERS_FAILURE, payload: error };
};

const deactivateWPUsersSuccess = (users) => {
  return { type: usersConstants.DEACTIVATE_WP_USERS_SUCCESS, payload: users.data };
};

const deactivateWPUsersFailure = (error) => {
  return { type: usersConstants.DEACTIVATE_WP_USERS_FAILURE, payload: error };
};

const exportUsersSuccess = (usersData) => {
  return { type: usersConstants.EXPORT_USERS_SUCCESS, payload: usersData };
};

const exportUsersFailure = (error) => {
  return { type: usersConstants.EXPORT_USERS_FAILURE, payload: error };
};

const validateUserSuccess = (validation_messages) => {
  return { type: usersConstants.VALIDATE_USER_SUCCESS, payload: validation_messages };
};

const validateUserFailure = (error) => {
  return { type: usersConstants.VALIDATE_USER_FAILURE, payload: error };
};

const addWPUserSuccess = (user) => {
  return { type: usersConstants.ADD_WP_USER_SUCCESS, payload: user.data };
};

const addWPUserFailure = (error) => {
  return { type: usersConstants.ADD_WP_USER_FAILURE, payload: error };
};

const updateWPUserSuccess = (user) => {
  return { type: usersConstants.UPDATE_WP_USER_SUCCESS, payload: user.data };
};

const updateWPUserFailure = (error) => {
  return { type: usersConstants.UPDATE_WP_USER_FAILURE, payload: error };
};

const updateWPUserStatusSuccess = (user) => {
  return { type: usersConstants.UPDATE_WP_USER_STATUS_SUCCESS, payload: user.data };
};

const updateWPUserStatusFailure = (error) => {
  return { type: usersConstants.UPDATE_WP_USER_STATUS_FAILURE, payload: error };
};

const bulkUsersStatusSuccess = (data) => {
  return { type: usersConstants.BULK_USERS_STATUS_SUCCESS, payload: data };
};

const bulkUsersStatusFailure = (error) => {
  return { type: usersConstants.BULK_USERS_STATUS_FAILURE, payload: error };
};

export const usersDispatches = {
  fetchUsersSuccess,
  fetchUsersFailure,
  setFetchUsersLoading,
  addUserSuccess,
  addUserFailure,
  updateUserSuccess,
  updateUserFailure,
  resetUserPasswordSuccess,
  updateUserActiveSuccess,
  blockUsersSuccess,
  blockUsersFailure,
  deactivateWPUsersSuccess,
  deactivateWPUsersFailure,
  exportUsersSuccess,
  exportUsersFailure,
  validateUserSuccess,
  validateUserFailure,
  addWPUserSuccess,
  addWPUserFailure,
  updateWPUserSuccess,
  updateWPUserFailure,
  updateWPUserStatusSuccess,
  updateWPUserStatusFailure,
  bulkUsersStatusSuccess,
  bulkUsersStatusFailure
};

export const PLAN_FEATURES = {
  TRIAL: {
    MONTHLY_ACTIVE_USERS: 20,
    NUMBER_OF_REGISTERED_USERS: 20
  },
  ESSENTIALS: {
    MONTHLY_ACTIVE_USERS: 20000,
    NUMBER_OF_REGISTERED_USERS: 1000000
  },
  ELITE: {
    MONTHLY_ACTIVE_USERS: 50000,
    NUMBER_OF_REGISTERED_USERS: 1000000
  },
  LEGACY: {
    MONTHLY_ACTIVE_USERS: 99999999,
    NUMBER_OF_REGISTERED_USERS: 99999999
  },
  EDLY_SAAS: {
    MONTHLY_ACTIVE_USERS: 99999999,
    NUMBER_OF_REGISTERED_USERS: 99999999
  }
};

export const SERVICES_FILTERS = {
  LMS: [
    {
      id: 'super_admin',
      label: 'Site Admin'
    },
    {
      id: 'staff',
      label: 'Staff'
    },
    {
      id: 'course_creator',
      label: 'Course Creator'
    },
    {
      id: 'learner',
      label: 'Learner'
    }
  ],
  Discovery: [
    {
      id: 'subscriber',
      label: 'Subscriber'
    },
    {
      id: 'edly_admin',
      label: 'Edly Admin'
    }
  ],
  Panel: [
    {
      id: 'panel_restricted',
      label: 'Restricted'
    },
    {
      id: 'panel_user',
      label: 'User'
    },
    {
      id: 'insights_admin',
      label: 'Insights Viewer'
    },
    {
      id: 'panel_admin',
      label: 'Panel Admin'
    },
    {
      id: 'panel_super_admin',
      label: 'Panel Super Admin'
    }
  ]
};

export const USER_ROLES_FILTER = [
  {
    id: 'is_active',
    label: 'Activated'
  },
  {
    id: 'is_deactivated',
    label: 'Deactivated'
  },
  {
    id: 'is_blocked',
    label: 'Blocked'
  },
  {
    id: 'is_unblocked',
    label: 'Not Blocked'
  },
  {
    id: 'retired',
    label: 'Retired'
  }
];

export const USER_ROLES = [
  'super_admin',
  'staff',
  'course_creator',
  'learner',
  'subscriber',
  'edly_admin',
  'panel_restricted',
  'panel_user',
  'panel_admin',
  'insights_admin'
];

export const EDIT_USER_FIELD = [
  'username',
  'name',
  'email',
  'is_staff',
  'is_active',
  'is_superuser',
  'lms_role',
  'wordpress_role',
  'panel_role',
  'is_blocked'
];
