import React, { useState, useEffect } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { SERVICES_FILTERS, USER_ROLES, USER_ROLES_FILTER } from 'pages/users/containers/usersContainer/constants';

import RoleCheckBox from 'pages/users/containers/usersContainer/components/roleCheckBox';
import { useClickAway } from 'use-click-away';
import { IconFilter } from '@tabler/icons-react';

const RolesFilter = (props) => {
  const { handleRolesFilter, roles } = props;
  const ref = React.useRef('');
  const [checkAllRoles, setCheckAllRoles] = useState(false);
  const [activeSelection, setActiveSelection] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState({
    ...roles,
    is_active: true,
    is_deactivated: true
  });
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    checkRolesSelection(selectedRoles);
    let users = Object.keys(selectedRoles).filter(function(key) {
      return selectedRoles[key] === false;
    });

    if (users.length > 0) {
      setCheckAllRoles(false);
    } else {
      setCheckAllRoles(true);
    }
  }, [selectedRoles]);

  const handleSelectedRoles = (event) => {
    let selectedRoleName = event.target.value;
    let status = event.target.checked;
    const updateSelectedRole = {
      ...selectedRoles,
      [selectedRoleName]: status
    };
    setSelectedRoles(updateSelectedRole);
    setCheckAllRoles(false);
    checkRolesSelection(updateSelectedRole);
  };

  useEffect(() => {
    handleRolesFilter(selectedRoles);
    // eslint-disable-next-line
  }, []);

  const getResetUserRoleObject = (status, is_active = true) => {
    const userRoleWithStatusValue = USER_ROLES.reduce(function(acc, key) {
      acc[key] = status;
      return acc;
    }, {});
    if (!is_active) {
      return userRoleWithStatusValue;
    }
    return {
      ...userRoleWithStatusValue,
      is_active
    };
  };

  const handleSelectAllRoles = (event) => {
    let status = event.target.checked;
    const select_all_roles = getResetUserRoleObject(status, false);
    setActiveSelection(status);
    setSelectedRoles(select_all_roles);
    setCheckAllRoles(status);
    checkRolesSelection(select_all_roles);
  };

  const checkRolesSelection = (selectedRoles) => {
    let users = Object.keys(selectedRoles).filter(function(key) {
      return selectedRoles[key] === true;
    });
    setActiveSelection(!!users.length);
  };

  const resetFilter = (event) => {
    const select_all_roles = getResetUserRoleObject(false);
    setSelectedRoles(select_all_roles);
    handleRolesFilter(select_all_roles);
    setIsActive(false);
  };

  useClickAway(ref, () => {
    setIsActive(false);
  });

  return (
    <li className={activeSelection ? 'active user-filter-drop' : 'user-filter-drop'}>
      <NavDropdown
        show={isActive}
        ref={ref}
        title={
          <Button
            className="p-0"
            size="sm"
            variant="link"
            title="Filter on Selected Roles"
            onClick={() => {
              setIsActive((prev) => !prev);
            }}
          >
            <IconFilter stroke={1.5} />
            <span className="text d-none-md">Filters</span>
          </Button>
        }
        id="filter_selected_roles"
        className="user-filters"
      >
        <div className="roles-frame">
          <div className="roles-col">
            <h6>Status</h6>
            <ul className="user-role-list">
              {USER_ROLES_FILTER.map((userRole, index) => {
                return (
                  <RoleCheckBox
                    key={index}
                    id={userRole['id']}
                    label={userRole['label']}
                    isChecked={selectedRoles[userRole['id']]}
                    handleChange={handleSelectedRoles}
                  />
                );
              })}
            </ul>
          </div>
        </div>
        <div className="search-roles">
          <ul className="user-role-list">
            <RoleCheckBox
              id="checkAllRoles"
              label="All Roles"
              isChecked={checkAllRoles === true}
              handleChange={handleSelectAllRoles}
            />
          </ul>
          <div className="roles-frame">
            {Object.keys(SERVICES_FILTERS).map((service, index) => {
              return (
                <div className="roles-col" key={index}>
                  <h6>{service}</h6>
                  <ul className="user-role-list">
                    {SERVICES_FILTERS[service].map((role, index) => {
                      return (
                        <RoleCheckBox
                          key={index}
                          id={role['id']}
                          label={role['label']}
                          isChecked={selectedRoles[role['id']]}
                          handleChange={handleSelectedRoles}
                        />
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div className="maus-filter-footer">
          <Button variant="outline-primary" size="sm" onClick={resetFilter}>
            Reset
          </Button>
          <Button
            className="ms-2"
            variant="primary"
            size="sm"
            onClick={(event) => {
              handleRolesFilter(selectedRoles);
              setIsActive(false);
            }}
          >
            Apply
          </Button>
        </div>
      </NavDropdown>
    </li>
  );
};

export default RolesFilter;
