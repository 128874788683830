import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSVLink } from 'react-csv';
import { Navbar, Nav, Button, Form } from 'react-bootstrap';
import { IconSearch, IconDownload, IconUserOff, IconFilter } from '@tabler/icons-react';

import PaginationDropdown from 'components/paginationDropdown';
import RolesFilter from 'pages/users/containers/usersContainer/components/filters/rolesFilter';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { messageNotification } from 'helpers';
import { useClickAway } from 'use-click-away';

const UserFilters = (props) => {
  const {
    roles,
    setRoles,
    userFilters,
    setSelectedUsers,
    timer,
    setTimer,
    search,
    setSearch,
    selectedUsers,
    users_info,
    users_data,
    current_page,
    pageSize,
    setPageSize,
    dispatch,
    setCheckAll
  } = props;

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navRef = React.useRef(null);

  useClickAway(navRef, () => {
    setIsNavExpanded(false);
  });

  const handleSearchFilter = (page_number, searchText) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ page_number, roles, searchText }));
      }, 3000)
    );
  };

  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setSearch(searchText);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ page_number: 1, roles, searchText, page_size: pageSize }));
      }, 400)
    );
  };

  const validateForAlreadyBlockedUsers = (users) => {
    const isAlreadyBlockUserExist = users.map((username) => {
      return Boolean(users_info.find((user) => user.username === username && user.is_blocked === true));
    });
    const findBlockUserIndex = isAlreadyBlockUserExist.findIndex((item) => item);
    if (findBlockUserIndex !== -1) {
      messageNotification(
        'warning',
        `Selected user is already blocked`,
        `The user with username ${users[findBlockUserIndex]} is already blocked, please select a different user.`
      );
      return false;
    }
    return true;
  };

  const handleBlockUsers = (e) => {
    let users = {};
    let orderBy = `${userFilters.column},${userFilters.direction}`;
    users = Object.keys(selectedUsers).filter(function(key) {
      return selectedUsers[key] === true;
    });
    if (users.length < 1) {
      messageNotification('warning', 'Select a user to deactivate', 'Please select at least one user to deactivate.');
    } else {
      if (validateForAlreadyBlockedUsers(users)) {
        dispatch(usersActions.blockUsers(users, search, current_page, roles, orderBy, pageSize));
        users_info.forEach(function(user) {
          users[user.username] = false;
        });
        setSelectedUsers(users);
        setCheckAll(false);
      }
    }
  };

  const handleExportUsers = (e) => {
    let users = Object.keys(selectedUsers).filter(function(key) {
      return selectedUsers[key] === true;
    });
    dispatch(usersActions.exportUsersInfo({ searchText: search, roles, users }));
  };

  const handleRolesFilter = (rolesSelected) => {
    setRoles(rolesSelected);
    dispatch(
      usersActions.fetchUsers({ page_number: 1, searchText: search, roles: rolesSelected, page_size: pageSize })
    );
  };

  return (
    <Fragment>
      <div className="page-content-header mb-3 user-content-head">
        <div className="sites-search-holder ms-0 full-md mb-3 mb-md-0">
          <IconSearch
            id="button-addon2"
            onClick={() => handleSearchFilter(1, search)}
            stroke={1.5}
            className="ico-search"
          />
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search users"
              name="search"
              id="search"
              value={search}
              onChange={handleSearchChange}
              aria-label="Search User"
            />
          </Form.Group>
        </div>
        <Navbar className="user-filter-holder p-0 ms-3 ms-xxl-0" expand="xxl" ref={navRef} expanded={isNavExpanded}>
          <Navbar.Toggle
            className="btn-icon p-0"
            aria-controls="basic-navbar-nav"
            onClick={() => setIsNavExpanded((prev) => !prev)}
          >
            <IconFilter stroke={1.5} />
          </Navbar.Toggle>
          <Navbar.Collapse id="filter-navbar">
            <Nav>
              <ul className="user-filter-list">
                <RolesFilter roles={roles} handleRolesFilter={handleRolesFilter} />
                <li>
                  <Button
                    className="p-0"
                    size="sm"
                    variant="link"
                    title="Export all or selected Users"
                    onClick={handleExportUsers}
                  >
                    <IconDownload stroke={1.5} />
                    <span className="text d-none-md">Export</span>
                  </Button>
                </li>
                <li className="d-none">
                  <CSVLink
                    filename="users_data.csv"
                    className="btn p-0 btn-sm btn-link btn-download-csv"
                    data={users_data}
                  >
                    <IconDownload stroke={1.5} />
                    <span className="text">
                      Export <span className="d-none-md">Users Data</span>
                    </span>
                  </CSVLink>
                </li>
                <li>
                  <Button
                    className="p-0"
                    size="sm"
                    variant="link"
                    title="Block Selected Users"
                    onClick={handleBlockUsers}
                  >
                    <IconUserOff stroke={1.5} />
                    <span className="text d-none-md">Block Users</span>
                  </Button>
                </li>
                <li>
                  <PaginationDropdown
                    setPageSize={setPageSize}
                    current_page={current_page}
                    search={search}
                    roles={roles}
                    orderBy={`${userFilters.column},${userFilters.direction}`}
                    dispatch={dispatch}
                  />
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { users_data } = state.USERS_EXPORT_DATA;

  return {
    users_data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(usersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFilters);
