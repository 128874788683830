import { connect } from 'react-redux';
import Slider from 'react-slick';
import React, { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';

import { CustomMenu, CustomToggle } from 'pages/dashboard/containers/sites/components/CustomMenu';
import { Dropdown, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { getOrganization, getUserPanelRole } from 'selectors';
import { PANEL_TLD_DOMAIN } from 'config';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import UserSite from 'pages/dashboard/containers/sites/components/userSite';
import UserSiteVerticalView from 'pages/dashboard/containers/sites/components/userSiteVertical';
import RowLoader from 'components/loaders/RowLoader';
import { IconSearch, IconLayoutGrid, IconListDetails, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { BILLING_PAGE } from 'components/ctaBanner/constants';
import { messageNotification } from 'helpers';

const UserSites = (props) => {
  const history = useHistory();

  const {
    isLoading,
    userSites,
    selectedSites,
    dispatch,
    creatingSite,
    setCreatingSite,
    handleSetLoading,
    panel_role,
    currentPlan
  } = props;
  const [searchOrg, setSearchOrg] = useState('');
  const [isVertical, setIsVertical] = useState(false);

  const handleSearchFilter = (event) => {
    event.preventDefault();
    const org = event.target.value;
    setSearchOrg(org);
  };

  useEffect(() => {
    dispatch(userSiteActions.fetchUserSites());
  }, [dispatch]);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <IconChevronLeft stroke={1.5} />,
    nextArrow: <IconChevronRight stroke={1.5} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
          autoplay: true
        }
      }
    ]
  };

  const handleClick = (site) => {
    dispatch(
      userSiteActions.UpdateSelectedSites({
        removedSelectedSite: selectedSites.includes(site),
        data: site
      })
    );
  };

  const filterSites = userSites.filter((item) => {
    return !searchOrg || item.slug.startsWith(searchOrg.toLowerCase());
  });
  const isCreateSiteVisible = window.location.origin.includes(PANEL_TLD_DOMAIN) && panel_role === 'super_admin';

  return (
    <div className="user-sites-detail">
      <div className="sites-head flex-column flex-lg-row">
        <div className="sites-heading d-flex mb-3 mb-lg-0 justify-content-between align-items-center d-flex">
          <h2 className="mb-0">Your sites</h2>
          <div className="site-switch-controls d-flex d-lg-none">
            <span
              className={`btn-icon ${isVertical ? 'icon-selected' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                setIsVertical(true);
                setSearchOrg('');
              }}
            >
              <IconListDetails stroke={1.5} />
            </span>
            <span
              className={`btn-icon ms-3 ${!isVertical ? 'icon-selected' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                setIsVertical(false);
                setSearchOrg('');
              }}
            >
              <IconLayoutGrid stroke={1.5} />
            </span>
          </div>
        </div>
        <div className="sites-controls">
          <div className="site-switch-controls d-none d-lg-flex">
            <span
              className={`btn-icon ${isVertical ? 'icon-selected' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                setIsVertical(true);
                setSearchOrg('');
              }}
            >
              <IconListDetails stroke={1.5} />
            </span>
            <span
              className={`btn-icon ${!isVertical ? 'icon-selected' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                setIsVertical(false);
                setSearchOrg('');
              }}
            >
              <IconLayoutGrid stroke={1.5} />
            </span>
          </div>
          {!isVertical && (
            <div className="sites-search-holder ms-0 ms-lg-3">
              <IconSearch stroke={1.5} className="ico-search" />
              <Form.Group>
                <Form.Control
                  type="text"
                  value={searchOrg}
                  onChange={handleSearchFilter}
                  name="searchSite"
                  placeholder="Search a site"
                  required
                />
              </Form.Group>
            </div>
          )}
          {isCreateSiteVisible && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Each new site is charged separately as a one-time setup fee</Tooltip>
              }
            >
              <Button
                variant="primary"
                style={{
                  display: creatingSite ? 'none' : 'block'
                }}
                className="ms-0 ms-sm-2"
                onClick={() => {
                  if (['trial'].includes(currentPlan)) {
                    messageNotification(
                      'info',
                      'Plan Upgrade Required!',
                      'Please activate your billing to create a new site.'
                    );
                    history.push(BILLING_PAGE);
                  } else {
                    setCreatingSite(true);
                  }
                }}
              >
                Create new site{' '}
              </Button>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {isLoading ? (
        <RowLoader />
      ) : !isVertical ? (
        filterSites.length !== 0 ? (
          <div className="app-block">
            <div className="sites-slider">
              <Slider {...settings}>
                <UserSite site={{ slug: 'all' }} />
                {filterSites.map((item, index) => {
                  return <UserSite handleSetLoading={handleSetLoading} site={item} key={index} />;
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <div>
            No site with this name:<b> {searchOrg} </b>
          </div>
        )
      ) : (
        <div className="app-block">
          <div className="sites-slider">
            <Dropdown style={{ minWidth: '100%', margin: '0' }}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="sites-dropdown">
                  {selectedSites.map((site, index) => {
                    return (
                      <div key={index} className="site-chips">
                        {site}{' '}
                        {site.toUpperCase() !== 'ALL' && (
                          <span
                            onClick={(event) => {
                              event.preventDefault();
                              handleClick(site);
                            }}
                            className="chips-close"
                          >
                            <IconX stroke={1.5} />
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="all">
                  <UserSiteVerticalView site={{ slug: 'all' }} />
                </Dropdown.Item>
                {userSites.map((item, index) => {
                  return (
                    <Dropdown.Item eventKey={item.slug} key={index}>
                      <UserSiteVerticalView handleSetLoading={handleSetLoading} site={item} key={index} />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  const { current_site_plan } = getOrganization(state);
  return {
    panel_role,
    userSites: state.USER_SITES.user_site,
    selectedSites: state.USER_SITES.selected_sites,
    isLoading: state.USER_SITES.isLoading,
    currentPlan: current_site_plan
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const connectedUserSite = connect(mapStateToProps, mapDispatchToProps)(UserSites);
export default connectedUserSite;
